<template>
  <main class="start" @click="start">
    <h3>
      <br /><br /><br /><br />
      <span v-if="!isaccount"> Willkommen</span>
      <span v-else> Willkommen zurück</span><br />bei Zugepflastert.de<br />Clicke
      zum Start
    </h3>
    <button></button>
  </main>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "start",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    if (typeof route.params.qr !== "undefined") {
      store.commit("set_location", route.params.qr);
    }
    function start() {
      if (store.state.loggedin) {
        if (store.state.takenimages >= store.state.maximages)
          router.push({ name: "overview" });
        else router.push({ name: "camera" });
      } else {
        router.push("register");
      }
    }
    return {
      start,
      isaccount: computed(() => {
        if (typeof store.state.account === "undefined") return false;
        else return true;
      }),
    };
  },
};
</script>
